import React from "react"
import Img from "gatsby-image"
import styles from "../../scss/modules/service_tile.module.scss"

export default (service) => {

  let image = '';
  if (service.image) {
    image = <div className={styles.service_tile__image_wrapper}>
      <Img className={styles.service_tile__image} fluid={ service.image.childImageSharp.fluid } />
    </div>
  }

  return (

    <div className={styles.service_tile}>
        <div className={styles.service_tile__inner}>
          <div className={styles.service_tile__content}>
              <h2>{service.title}</h2>
              <span>{service.subtitle}</span>
              <p>{ service.summary }</p>
          </div>
        </div>
    </div>
  )
}
