import React from "react";
import styles from "../../scss/modules/cta_block.module.scss";
import cta from "../../scss/elements/buttons.module.scss";
import { Link } from "gatsby"

export default (service) => (
    <div className={[styles.cta, styles.cta__pink_bg].join(' ')}>
        <div className={styles.cta__inner}>
            <div className={styles.cta__content}>
                <h2>{"Interested in "+service.title+" services?"}</h2>
                <p>{service.content}</p>
                <Link className={cta.arrow_button} to="/contact">Get In Touch</Link>
            </div>
            <div className={styles.cta__image}>
                <img src={service.image } alt={service.title+" Services"}></img>
            </div>
        </div>
    </div>
  )
  